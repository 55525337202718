import * as React from "react"
import { Link } from "gatsby"
import useCategories from "../hooks/use-categories"
import CategoryHeader from "../components/ui/category-header"
import MainLayout from "../components/layouts/main-layout"
import ProductCard from "../components/ui/product-card"
import useProducts from "../hooks/use-products"
import useCompany from "../hooks/use-company"

const CategoryTemplate = ({ pageContext }) => {
  const { id } = pageContext
  const { id: categoryId, slug, name, description, image, category, seo } = useCategories().getCategory(id)
  const parent = useCategories().getParentCategory(category)
  const categories = useCategories().getSubcategories(categoryId)
  const products = useProducts().getProductsByCategory(id)
  const company = useCompany().get()
  const _title = `${company.seo.title} | ${seo.title || name }`
  const _description = seo.description || `${description} ${company.seo.description}`
  return (
    <MainLayout title={_title} description={_description}>
      <CategoryHeader url={image.url} name={name} description={description} parent={parent} />
      <section className="container mb-3">
        {categories && categories.map((c) => (
          <Link
            key={c.id}
            to={`/categoria/${slug}/${c.slug}`}
            title={`Ver información de ${c.name}`}
            className="btn btn-outline-secondary shadow-none btn-sm rounded-0 me-2 mb-1">
            {c.name}
          </Link>
        ))}
      </section>
      <section className="container py-5">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 g-3">
          { products && products.map(({ id, name, excerpt, slug, image }) => (
            <div className="col" key={id}>
              <ProductCard name={name} excerpt={excerpt} slug={slug} image={image} />
            </div>
          ))}
        </div>
      </section>
    </MainLayout>
  )
}

export default CategoryTemplate;