import * as React from "react"
import { Link } from "gatsby"

const CategoryHeader = ({ url, name, description, parent }) => (
  <section className="bg-light">
    <article className="container">
      <div className="row d-flex justify-content-center py-5 mb-4 category-header">
        <div className="col-sm-12 col-md-6 col-lg-7 position-relative animate__animated animate__fadeIn animate__slower">
          <div className="category-image">
            <img src={url} alt={name} className="shadow bg-light" />
          </div>
          <div className="category-shadow bg-default shadow"></div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-5">
          <div className="mt-5">
            <h1>{name}</h1>
            <p className="fs-4 text-muted pe-1 pe-xl-5">{description}</p>
            {(parent) ? <Link to={`/categoria/${parent.slug}`} className="link-secondary">/ {parent.name}</Link> : <Link to={`/categoria`} className="link-secondary">/ Categorias</Link>}
          </div>
        </div>
      </div>
    </article>
  </section>
)

export default CategoryHeader